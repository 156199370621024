@mixin primer-colors-dark_high_contrast {
  & {
    --color-canvas-default-transparent: rgba(10,12,16,0);
    --color-page-header-bg: #0a0c10;
    --color-marketing-icon-primary: #91cbff;
    --color-marketing-icon-secondary: #409eff;
    --color-diff-blob-addition-num-text: #f0f3f6;
    --color-diff-blob-addition-fg: #0a0c10;
    --color-diff-blob-addition-num-bg: rgba(38,205,77,0.3);
    --color-diff-blob-addition-line-bg: rgba(9,180,58,0.15);
    --color-diff-blob-addition-word-bg: #09b43a;
    --color-diff-blob-deletion-num-text: #f0f3f6;
    --color-diff-blob-deletion-fg: #0a0c10;
    --color-diff-blob-deletion-num-bg: rgba(255,106,105,0.3);
    --color-diff-blob-deletion-line-bg: rgba(255,106,105,0.15);
    --color-diff-blob-deletion-word-bg: #ff6a69;
    --color-diff-blob-hunk-num-bg: rgba(64,158,255,0.4);
    --color-diff-blob-expander-icon: #f0f3f6;
    --color-diff-blob-selected-line-highlight-mix-blend-mode: screen;
    --color-diffstat-deletion-border: #ffb1af;
    --color-diffstat-addition-border: #4ae168;
    --color-diffstat-addition-bg: #26cd4d;
    --color-search-keyword-hl: rgba(240,183,47,0.4);
    --color-prettylights-syntax-comment: #bdc4cc;
    --color-prettylights-syntax-constant: #91cbff;
    --color-prettylights-syntax-entity: #dbb7ff;
    --color-prettylights-syntax-storage-modifier-import: #f0f3f6;
    --color-prettylights-syntax-entity-tag: #72f088;
    --color-prettylights-syntax-keyword: #ff9492;
    --color-prettylights-syntax-string: #addcff;
    --color-prettylights-syntax-variable: #ffb757;
    --color-prettylights-syntax-brackethighlighter-unmatched: #ff6a69;
    --color-prettylights-syntax-invalid-illegal-text: #ffffff;
    --color-prettylights-syntax-invalid-illegal-bg: #e82a2f;
    --color-prettylights-syntax-carriage-return-text: #ffffff;
    --color-prettylights-syntax-carriage-return-bg: #ff4445;
    --color-prettylights-syntax-string-regexp: #72f088;
    --color-prettylights-syntax-markup-list: #fbd669;
    --color-prettylights-syntax-markup-heading: #409eff;
    --color-prettylights-syntax-markup-italic: #f0f3f6;
    --color-prettylights-syntax-markup-bold: #f0f3f6;
    --color-prettylights-syntax-markup-deleted-text: #ffdedb;
    --color-prettylights-syntax-markup-deleted-bg: #cc1421;
    --color-prettylights-syntax-markup-inserted-text: #acf7b6;
    --color-prettylights-syntax-markup-inserted-bg: #007728;
    --color-prettylights-syntax-markup-changed-text: #ffe1b4;
    --color-prettylights-syntax-markup-changed-bg: #a74c00;
    --color-prettylights-syntax-markup-ignored-text: #f0f3f6;
    --color-prettylights-syntax-markup-ignored-bg: #318bf8;
    --color-prettylights-syntax-meta-diff-range: #dbb7ff;
    --color-prettylights-syntax-brackethighlighter-angle: #bdc4cc;
    --color-prettylights-syntax-sublimelinter-gutter-mark: #7a828e;
    --color-prettylights-syntax-constant-other-reference-link: #addcff;
    --color-codemirror-text: #f0f3f6;
    --color-codemirror-bg: #0a0c10;
    --color-codemirror-gutters-bg: #0a0c10;
    --color-codemirror-guttermarker-text: #0a0c10;
    --color-codemirror-guttermarker-subtle-text: #9ea7b3;
    --color-codemirror-linenumber-text: #f0f3f6;
    --color-codemirror-cursor: #f0f3f6;
    --color-codemirror-selection-bg: rgba(64,158,255,0.4);
    --color-codemirror-activeline-bg: rgba(158,167,179,0.1);
    --color-codemirror-matchingbracket-text: #f0f3f6;
    --color-codemirror-lines-bg: #0a0c10;
    --color-codemirror-syntax-comment: #bdc4cc;
    --color-codemirror-syntax-constant: #91cbff;
    --color-codemirror-syntax-entity: #dbb7ff;
    --color-codemirror-syntax-keyword: #ff9492;
    --color-codemirror-syntax-storage: #ff9492;
    --color-codemirror-syntax-string: #addcff;
    --color-codemirror-syntax-support: #91cbff;
    --color-codemirror-syntax-variable: #ffb757;
    --color-checks-bg: #010409;
    --color-checks-run-border-width: 1px;
    --color-checks-container-border-width: 1px;
    --color-checks-text-primary: #f0f3f6;
    --color-checks-text-secondary: #f0f3f6;
    --color-checks-text-link: #71b7ff;
    --color-checks-btn-icon: #f0f3f6;
    --color-checks-btn-hover-icon: #f0f3f6;
    --color-checks-btn-hover-bg: rgba(158,167,179,0.1);
    --color-checks-input-text: #f0f3f6;
    --color-checks-input-placeholder-text: #9ea7b3;
    --color-checks-input-focus-text: #f0f3f6;
    --color-checks-input-bg: #272b33;
    --color-checks-input-shadow: 0 0 0 1px (obj) => (0, get_1.default)(obj, path);
    --color-checks-donut-error: #ff6a69;
    --color-checks-donut-pending: #f0b72f;
    --color-checks-donut-success: #09b43a;
    --color-checks-donut-neutral: #bdc4cc;
    --color-checks-dropdown-text: #f0f3f6;
    --color-checks-dropdown-bg: #272b33;
    --color-checks-dropdown-border: #7a828e;
    --color-checks-dropdown-shadow: rgba(1,4,9,0.3);
    --color-checks-dropdown-hover-text: #f0f3f6;
    --color-checks-dropdown-hover-bg: rgba(158,167,179,0.1);
    --color-checks-dropdown-btn-hover-text: #f0f3f6;
    --color-checks-dropdown-btn-hover-bg: rgba(158,167,179,0.1);
    --color-checks-scrollbar-thumb-bg: rgba(158,167,179,0.4);
    --color-checks-header-label-text: #f0f3f6;
    --color-checks-header-label-open-text: #f0f3f6;
    --color-checks-header-border: #7a828e;
    --color-checks-header-icon: #f0f3f6;
    --color-checks-line-text: #f0f3f6;
    --color-checks-line-num-text: #9ea7b3;
    --color-checks-line-timestamp-text: #9ea7b3;
    --color-checks-line-hover-bg: rgba(158,167,179,0.1);
    --color-checks-line-selected-bg: rgba(64,158,255,0.15);
    --color-checks-line-selected-num-text: #71b7ff;
    --color-checks-line-dt-fm-text: #0a0c10;
    --color-checks-line-dt-fm-bg: #e09b13;
    --color-checks-gate-bg: rgba(224,155,19,0.15);
    --color-checks-gate-text: #f0f3f6;
    --color-checks-gate-waiting-text: #f0b72f;
    --color-checks-step-header-open-bg: #272b33;
    --color-checks-step-error-text: #ff6a69;
    --color-checks-step-warning-text: #f0b72f;
    --color-checks-logline-text: #f0f3f6;
    --color-checks-logline-num-text: #9ea7b3;
    --color-checks-logline-debug-text: #b780ff;
    --color-checks-logline-error-text: #f0f3f6;
    --color-checks-logline-error-num-text: #9ea7b3;
    --color-checks-logline-error-bg: rgba(255,106,105,0.15);
    --color-checks-logline-warning-text: #f0f3f6;
    --color-checks-logline-warning-num-text: #f0b72f;
    --color-checks-logline-warning-bg: rgba(224,155,19,0.15);
    --color-checks-logline-command-text: #71b7ff;
    --color-checks-logline-section-text: #26cd4d;
    --color-checks-ansi-black: #0a0c10;
    --color-checks-ansi-black-bright: #272b33;
    --color-checks-ansi-white: #d9dee3;
    --color-checks-ansi-white-bright: #d9dee3;
    --color-checks-ansi-gray: #9ea7b3;
    --color-checks-ansi-red: #ff9492;
    --color-checks-ansi-red-bright: #ffb1af;
    --color-checks-ansi-green: #26cd4d;
    --color-checks-ansi-green-bright: #4ae168;
    --color-checks-ansi-yellow: #f0b72f;
    --color-checks-ansi-yellow-bright: #f7c843;
    --color-checks-ansi-blue: #71b7ff;
    --color-checks-ansi-blue-bright: #91cbff;
    --color-checks-ansi-magenta: #cb9eff;
    --color-checks-ansi-magenta-bright: #dbb7ff;
    --color-checks-ansi-cyan: #76e3ea;
    --color-checks-ansi-cyan-bright: #b3f0ff;
    --color-project-header-bg: #0a0c10;
    --color-project-sidebar-bg: #272b33;
    --color-project-gradient-in: #272b33;
    --color-project-gradient-out: rgba(39,43,51,0);
    --color-mktg-btn-bg: #f6f8fa;
    --color-mktg-btn-shadow-outline: rgb(255 255 255 / 25%) 0 0 0 1px inset;
    --color-mktg-btn-shadow-focus: rgb(255 255 255 / 25%) 0 0 0 4px;
    --color-mktg-btn-shadow-hover: 0 4px 7px rgba(0, 0, 0, 0.15), 0 100px 80px rgba(255, 255, 255, 0.02), 0 42px 33px rgba(255, 255, 255, 0.024), 0 22px 18px rgba(255, 255, 255, 0.028), 0 12px 10px rgba(255, 255, 255, 0.034), 0 7px 5px rgba(255, 255, 255, 0.04), 0 3px 2px rgba(255, 255, 255, 0.07);
    --color-mktg-btn-shadow-hover-muted: rgb(255 255 255) 0 0 0 2px inset;
    --color-avatar-bg: rgba(255,255,255,0.1);
    --color-avatar-border: rgba(255,255,255,0.9);
    --color-avatar-stack-fade: #525964;
    --color-avatar-stack-fade-more: #272b33;
    --color-avatar-child-shadow: -2px -2px 0 #0a0c10;
    --color-topic-tag-border: #409eff;
    --color-counter-border: rgba(0,0,0,0);
    --color-select-menu-backdrop-border: #7a828e;
    --color-select-menu-tap-highlight: rgba(82,89,100,0.5);
    --color-select-menu-tap-focus-bg: #1e60d5;
    --color-overlay-shadow: 0 0 0 1px #525964, 0 16px 32px rgba(1,4,9,0.85);
    --color-header-text: rgba(255,255,255,0.7);
    --color-header-bg: #272b33;
    --color-header-divider: #bdc4cc;
    --color-header-logo: #ffffff;
    --color-header-search-bg: #0a0c10;
    --color-header-search-border: #525964;
    --color-sidenav-selected-bg: #272b33;
    --color-menu-bg-active: #272b33;
    --color-input-disabled-bg: rgba(158,167,179,0);
    --color-timeline-badge-bg: #272b33;
    --color-ansi-black: #7a828e;
    --color-ansi-black-bright: #9ea7b3;
    --color-ansi-white: #d9dee3;
    --color-ansi-white-bright: #ffffff;
    --color-ansi-gray: #9ea7b3;
    --color-ansi-red: #ff9492;
    --color-ansi-red-bright: #ffb1af;
    --color-ansi-green: #26cd4d;
    --color-ansi-green-bright: #4ae168;
    --color-ansi-yellow: #f0b72f;
    --color-ansi-yellow-bright: #f7c843;
    --color-ansi-blue: #71b7ff;
    --color-ansi-blue-bright: #91cbff;
    --color-ansi-magenta: #cb9eff;
    --color-ansi-magenta-bright: #dbb7ff;
    --color-ansi-cyan: #39c5cf;
    --color-ansi-cyan-bright: #56d4dd;
    --color-btn-text: #f0f3f6;
    --color-btn-bg: #272b33;
    --color-btn-border: #7a828e;
    --color-btn-shadow: 0 0 transparent;
    --color-btn-inset-shadow: 0 0 transparent;
    --color-btn-hover-bg: #525964;
    --color-btn-hover-border: #bdc4cc;
    --color-btn-active-bg: hsla(217,10%,33%,1);
    --color-btn-active-border: #9ea7b3;
    --color-btn-selected-bg: rgba(82,89,100,0.9);
    --color-btn-focus-bg: #272b33;
    --color-btn-focus-border: #bdc4cc;
    --color-btn-focus-shadow: 0 0 0 3px rgba(189,196,204,0.3);
    --color-btn-shadow-active: inset 0 0.15em 0.3em rgba(1,4,9,0.15);
    --color-btn-shadow-input-focus: 0 0 0 0.2em rgba(64,158,255,0.3);
    --color-btn-counter-bg: #525964;
    --color-btn-primary-text: #0a0c10;
    --color-btn-primary-bg: #09b43a;
    --color-btn-primary-border: #4ae168;
    --color-btn-primary-shadow: 0 0 transparent;
    --color-btn-primary-inset-shadow: 0 0 transparent;
    --color-btn-primary-hover-bg: #26cd4d;
    --color-btn-primary-hover-border: #4ae168;
    --color-btn-primary-selected-bg: #09b43a;
    --color-btn-primary-selected-shadow: 0 0 transparent;
    --color-btn-primary-disabled-text: rgba(10,12,16,0.5);
    --color-btn-primary-disabled-bg: rgba(9,180,58,0.6);
    --color-btn-primary-disabled-border: rgba(74,225,104,0.4);
    --color-btn-primary-focus-bg: #09b43a;
    --color-btn-primary-focus-border: #7a828e;
    --color-btn-primary-focus-shadow: 0 0 0 3px rgba(46,164,79,0.4);
    --color-btn-primary-icon: #0a0c10;
    --color-btn-primary-counter-bg: rgba(1,4,9,0.15);
    --color-btn-outline-text: #71b7ff;
    --color-btn-outline-hover-text: #71b7ff;
    --color-btn-outline-hover-bg: #525964;
    --color-btn-outline-hover-border: #7a828e;
    --color-btn-outline-hover-shadow: 0 1px 0 rgba(1,4,9,0.1);
    --color-btn-outline-hover-inset-shadow: inset 0 1px 0 rgba(255,255,255,0.03);
    --color-btn-outline-hover-counter-bg: rgba(255,255,255,0.2);
    --color-btn-outline-selected-text: #ffffff;
    --color-btn-outline-selected-bg: #2672f3;
    --color-btn-outline-selected-border: #7a828e;
    --color-btn-outline-selected-shadow: 0 0 transparent;
    --color-btn-outline-disabled-text: rgba(113,183,255,0.5);
    --color-btn-outline-disabled-bg: #0a0c10;
    --color-btn-outline-disabled-counter-bg: rgba(64,158,255,0.05);
    --color-btn-outline-focus-border: #7a828e;
    --color-btn-outline-focus-shadow: 0 0 0 3px rgba(49,139,248,0.4);
    --color-btn-outline-counter-bg: rgba(64,158,255,0.1);
    --color-btn-danger-text: #ff6a69;
    --color-btn-danger-hover-text: #0a0c10;
    --color-btn-danger-hover-bg: #ff6a69;
    --color-btn-danger-hover-border: #ff6a69;
    --color-btn-danger-hover-shadow: 0 0 transparent;
    --color-btn-danger-hover-inset-shadow: 0 0 transparent;
    --color-btn-danger-hover-icon: #0a0c10;
    --color-btn-danger-hover-counter-bg: rgba(1,4,9,0.15);
    --color-btn-danger-selected-text: #ffffff;
    --color-btn-danger-selected-bg: #ff4445;
    --color-btn-danger-selected-border: #ff9492;
    --color-btn-danger-selected-shadow: 0 0 transparent;
    --color-btn-danger-disabled-text: rgba(255,106,105,0.5);
    --color-btn-danger-disabled-bg: #0a0c10;
    --color-btn-danger-disabled-counter-bg: rgba(255,106,105,0.05);
    --color-btn-danger-focus-border: #ff6a69;
    --color-btn-danger-focus-shadow: 0 0 0 3px rgba(255,106,105,0.4);
    --color-btn-danger-counter-bg: rgba(1,4,9,0.15);
    --color-btn-danger-icon: #ff6a69;
    --color-underlinenav-icon: #f0f3f6;
    --color-underlinenav-border-hover: #bdc4cc;
    --color-action-list-item-inline-divider: #7a828e;
    --color-action-list-item-default-hover-bg: #272b33;
    --color-action-list-item-default-hover-border: #7a828e;
    --color-action-list-item-default-active-bg: #525964;
    --color-action-list-item-default-active-border: #9ea7b3;
    --color-action-list-item-default-selected-bg: #525964;
    --color-action-list-item-danger-hover-bg: #ff6a69;
    --color-action-list-item-danger-active-bg: #ff4445;
    --color-action-list-item-danger-hover-text: #0a0c10;
    --color-switch-track-bg: #010409;
    --color-switch-track-border: #7a828e;
    --color-switch-track-checked-bg: rgba(64,158,255,0.35);
    --color-switch-track-checked-hover-bg: rgba(64,158,255,0.5);
    --color-switch-track-checked-active-bg: rgba(64,158,255,0.65);
    --color-switch-track-checked-border: #409eff;
    --color-switch-knob-checked-bg: #409eff;
    --color-switch-knob-checked-disabled-bg: #7a828e;
    --color-segmented-control-bg: #272b33;
    --color-segmented-control-button-hover-bg: #525964;
    --color-segmented-control-button-active-bg: #272b33;
    --color-segmented-control-button-selected-border: #9ea7b3;
    --color-tree-view-item-chevron-hover-bg: #525964;
    --color-tree-view-item-directory-fill: #f0f3f6;
    --color-fg-default: #f0f3f6;
    --color-fg-muted: #f0f3f6;
    --color-fg-subtle: #9ea7b3;
    --color-fg-on-emphasis: #0a0c10;
    --color-canvas-default: #0a0c10;
    --color-canvas-overlay: #272b33;
    --color-canvas-inset: #010409;
    --color-canvas-subtle: #272b33;
    --color-border-default: #7a828e;
    --color-border-muted: #7a828e;
    --color-border-subtle: #7a828e;
    --color-shadow-small: 0 0 transparent;
    --color-shadow-medium: 0 3px 6px #010409;
    --color-shadow-large: 0 8px 24px #010409;
    --color-shadow-extra-large: 0 12px 48px #010409;
    --color-neutral-emphasis-plus: #ffffff;
    --color-neutral-emphasis: #9ea7b3;
    --color-neutral-muted: rgba(158,167,179,0.4);
    --color-neutral-subtle: rgba(158,167,179,0.1);
    --color-accent-fg: #71b7ff;
    --color-accent-emphasis: #409eff;
    --color-accent-muted: #409eff;
    --color-accent-subtle: rgba(64,158,255,0.15);
    --color-success-fg: #26cd4d;
    --color-success-emphasis: #09b43a;
    --color-success-muted: #09b43a;
    --color-success-subtle: rgba(9,180,58,0.15);
    --color-attention-fg: #f0b72f;
    --color-attention-emphasis: #e09b13;
    --color-attention-muted: #e09b13;
    --color-attention-subtle: rgba(224,155,19,0.15);
    --color-severe-fg: #e7811d;
    --color-severe-emphasis: #e7811d;
    --color-severe-muted: #e7811d;
    --color-severe-subtle: rgba(231,129,29,0.15);
    --color-danger-fg: #ff6a69;
    --color-danger-emphasis: #ff6a69;
    --color-danger-muted: #ff6a69;
    --color-danger-subtle: rgba(255,106,105,0.15);
    --color-open-fg: #26cd4d;
    --color-open-emphasis: #09b43a;
    --color-open-muted: rgba(9,180,58,0.4);
    --color-open-subtle: rgba(9,180,58,0.15);
    --color-closed-fg: #ff6a69;
    --color-closed-emphasis: #ff6a69;
    --color-closed-muted: rgba(255,106,105,0.4);
    --color-closed-subtle: rgba(255,106,105,0.15);
    --color-done-fg: #b780ff;
    --color-done-emphasis: #b87fff;
    --color-done-muted: #b780ff;
    --color-done-subtle: rgba(183,128,255,0.15);
    --color-sponsors-fg: #ef6eb1;
    --color-sponsors-emphasis: #ef6eb1;
    --color-sponsors-muted: #ef6eb1;
    --color-sponsors-subtle: rgba(239,110,177,0.15);
    --color-primer-fg-disabled: #7a828e;
    --color-primer-canvas-backdrop: rgba(1,4,9,0.8);
    --color-primer-canvas-sticky: rgba(10,12,16,0.95);
    --color-primer-border-active: #ff967d;
    --color-primer-border-contrast: rgba(255,255,255,0.2);
    --color-primer-shadow-highlight: 0 0 transparent;
    --color-primer-shadow-inset: 0 0 transparent;
    --color-primer-shadow-focus: 0 0 0 3px #1e60d5;
    --color-scale-black: #010409;
    --color-scale-white: #ffffff;
    --color-scale-gray-0: #ffffff;
    --color-scale-gray-1: #f0f3f6;
    --color-scale-gray-2: #d9dee3;
    --color-scale-gray-3: #bdc4cc;
    --color-scale-gray-4: #9ea7b3;
    --color-scale-gray-5: #7a828e;
    --color-scale-gray-6: #525964;
    --color-scale-gray-7: #272b33;
    --color-scale-gray-8: #272b33;
    --color-scale-gray-9: #0a0c10;
    --color-scale-blue-0: #caeaff;
    --color-scale-blue-1: #addcff;
    --color-scale-blue-2: #91cbff;
    --color-scale-blue-3: #71b7ff;
    --color-scale-blue-4: #409eff;
    --color-scale-blue-5: #409eff;
    --color-scale-blue-6: #318bf8;
    --color-scale-blue-7: #2672f3;
    --color-scale-blue-8: #1e60d5;
    --color-scale-blue-9: #194fb1;
    --color-scale-green-0: #acf7b6;
    --color-scale-green-1: #72f088;
    --color-scale-green-2: #4ae168;
    --color-scale-green-3: #26cd4d;
    --color-scale-green-4: #09b43a;
    --color-scale-green-5: #09b43a;
    --color-scale-green-6: #02a232;
    --color-scale-green-7: #008c2c;
    --color-scale-green-8: #007728;
    --color-scale-green-9: #006222;
    --color-scale-yellow-0: #fbe59e;
    --color-scale-yellow-1: #fbd669;
    --color-scale-yellow-2: #f7c843;
    --color-scale-yellow-3: #f0b72f;
    --color-scale-yellow-4: #e09b13;
    --color-scale-yellow-5: #e09b13;
    --color-scale-yellow-6: #c88508;
    --color-scale-yellow-7: #ae7104;
    --color-scale-yellow-8: #945d02;
    --color-scale-yellow-9: #7b4900;
    --color-scale-orange-0: #ffe1b4;
    --color-scale-orange-1: #ffcf86;
    --color-scale-orange-2: #ffb757;
    --color-scale-orange-3: #fe9a2d;
    --color-scale-orange-4: #e7811d;
    --color-scale-orange-5: #e7811d;
    --color-scale-orange-6: #d57014;
    --color-scale-orange-7: #bf5e0a;
    --color-scale-orange-8: #a74c00;
    --color-scale-orange-9: #8f3c00;
    --color-scale-red-0: #ffdedb;
    --color-scale-red-1: #ffc9c7;
    --color-scale-red-2: #ffb1af;
    --color-scale-red-3: #ff9492;
    --color-scale-red-4: #ff6a69;
    --color-scale-red-5: #ff6a69;
    --color-scale-red-6: #ff4445;
    --color-scale-red-7: #e82a2f;
    --color-scale-red-8: #cc1421;
    --color-scale-red-9: #ad0116;
    --color-scale-purple-0: #f0dfff;
    --color-scale-purple-1: #e6ccff;
    --color-scale-purple-2: #dbb7ff;
    --color-scale-purple-3: #cb9eff;
    --color-scale-purple-4: #b780ff;
    --color-scale-purple-5: #b87fff;
    --color-scale-purple-6: #a66bff;
    --color-scale-purple-7: #954ffd;
    --color-scale-purple-8: #8031f7;
    --color-scale-purple-9: #6921d7;
    --color-scale-pink-0: #ffdceb;
    --color-scale-pink-1: #ffc7e1;
    --color-scale-pink-2: #ffadd4;
    --color-scale-pink-3: #ff8dc7;
    --color-scale-pink-4: #ef6eb1;
    --color-scale-pink-5: #ef6eb1;
    --color-scale-pink-6: #e456a3;
    --color-scale-pink-7: #d23d91;
    --color-scale-pink-8: #b72c7d;
    --color-scale-pink-9: #9c1d6a;
    --color-scale-coral-0: #ffded4;
    --color-scale-coral-1: #ffcbb9;
    --color-scale-coral-2: #ffb39b;
    --color-scale-coral-3: #ff967d;
    --color-scale-coral-4: #fc704f;
    --color-scale-coral-5: #fc704f;
    --color-scale-coral-6: #f75133;
    --color-scale-coral-7: #e03b21;
    --color-scale-coral-8: #c62612;
    --color-scale-coral-9: #a91500;
  }
}
